import './MapContainer.css';
import React, { useRef, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import mapboxgl from 'mapbox-gl';

import { useAuth } from '../../firebase/auth';
import { useMapData } from '../../providers/MapDataProvider';
import FirebaseRealtimeDatabaseProvider from '../../providers/FirebaseRealtimeDatabaseProvider';

import Popup from '../Popup/Popup';

import locationIcon from '../../images/location.svg';
import activeLocationIcon from '../../images/location-active.svg';
import selectedLocationIcon from '../../images/location-selected.svg';

//Map type can be 'interactive' (to allow for adding/removing components) or 'display' (for printing a trip)
export default function MapContainer({ latitude, longitude, zoom, trip, setTrip, mapType = 'interactive', tripType }) {
    const { authUser } = useAuth();
    
    const dataProvider = FirebaseRealtimeDatabaseProvider();

    const { clubs, locations, allTrails, mapDataLoading } = useMapData();

    const [trails, setTrails] = useState([]);

    //Stores current event listeners (click events) on the map. 
    //These are stored so they can be refreshed when the state 
    //of the trip changes.
    const eventListeners = useRef([]);

    const map = useRef(null);
    const mapContainer = useRef(null);
    
    //Stores the bounds of the map based on the layers added
    const mapBounds = useRef(null);

    //Stores the map and trail layer ids present on the map
    const mapLayerIds = useRef([]);
    const trailLayerIds = useRef([]);
    
    //Stores the layer ids for click targets for trails
    const trailTargetLayerIds = useRef([]);

    //Stores the location layer ids present on the map. Note that 
    //this is just for specific locations and doesn't include the 
    //primary "locations" layer.
    const locationLayerIds = useRef([]);

    const [mapLoading, setMapLoading] = useState(true);
    const [currentPopup, setCurrentPopup] = useState(null);

    /**
     * Adds a location or trail to the trip
     * 
     * @param {string} type Type of component ('trail' or 'location')
     * @param {Object} id Component identifier
     * @return {void}
     */
    const addTripComponent = (type, id) => {
        let tripData = {};
        if (trip) {
            tripData = {
                ...trip,
                components: {
                    ...trip.components,
                    [type + '-' + id]: {
                        type: type,
                        id: id
                    }
                }
            };
        } else {
            //If there's no current trip, initialize one
            tripData = {
                name: 'Your Trip',
                user: authUser.id,
                components: {
                    [type + '-' + id]: {
                        type: type,
                        id: id
                    }
                }
            };
        }

        const { mileage, duration } = calculateMileageAndDuration(tripData);
    
        tripData = {
            ...tripData,
            mileage: mileage,
            duration: duration
        };

        //Create or update the trail in the database and internal state
        if (trip) {
            dataProvider.update('trips', { id: tripData.id, data: tripData })
                .then((result) => {
                    setTrip(result.data);
                });
        } else {
            dataProvider.create('trips', { data: tripData })
                .then((result) => {
                    setTrip(result.data);
                });
        }
    };

    /**
     * Calculates a trip's mileage and duration
     * 
     * @param {Object} trip Trip record
     * @return {Object} {mileage, duration}
     */    
    const calculateMileageAndDuration = (trip) => {
        let mileage = 0;
        let duration = 0;

        if (trip?.components) {
            for (const key in trip.components) {
                if (trip.components[key].type === 'trail') {
                    const component = getTripComponent('trail', trip.components[key].id);
                    mileage += component.mileage;
                    duration += component.duration;
                }
            }
        }

        return { mileage, duration };
    };

    /**
     * Fetches the record for a trip component
     * 
     * @param {string} type Type of component ('trail' or 'location')
     * @param {Object} id Component identifier
     * @return {Object} Data from the record
     */
    const getTripComponent = (type, id) => {
        switch (type) {
            case 'location':
                return locations.find((location) => location.id === id);
            case 'trail':
            default:
                return allTrails.find((trail) => trail.id === id);
        }
    };

    /**
     * Checks if a trip already includes a location or trail
     * 
     * @param {string} type Type of component ('trail' or 'location')
     * @param {Object} id Component identifier
     * @return {Boolean} Is trip component already in the trip?
     */
    const hasTripComponent = (type, id) => {
        if (!trip?.components) return false;

        return Boolean(trip.components[type + '-' + id]);
    };

    /**
     * Removes a location or trail to the trip
     * 
     * @param {string} type Type of component ('trail' or 'location')
     * @param {Object} id Component identifier
     * @return {void}
     */
    const removeTripComponent = (type, id) => {
        const { [type + '-' + id]: _, ...updatedComponents } = trip.components;

        let tripData = {
            ...trip,
            components: updatedComponents
        };

        const { mileage, duration } = calculateMileageAndDuration(tripData);
    
        tripData = {
            ...tripData,
            mileage: mileage,
            duration: duration
        };

        //Update the trail in the database and internal state
        dataProvider.update('trips', { id: tripData.id, data: tripData })
            .then((result) => {
                setTrip(result.data);
            });
    };

    const customAttribution = new mapboxgl.AttributionControl({
        compact: true,
        customAttribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a>'
    });

    //Handle when a user clicks on a location
    const handleLocationClick = (e) => {
        e.preventDefault();
        const id = 'location-' + e.features[0].properties.Id

        //All locations generally share a single layer on the map. When 
        //an individual location is selected, create a specific layer 
        //for that location if it doesn't already exist, or change the 
        //icon if it does.
        if (map.current.getLayer(id)) {
            map.current.setLayoutProperty(id, 'icon-image', 'location-active')
        } else {
            map.current.addLayer({
                'id': id,
                'type': 'symbol',
                'source': {
                    'type': 'geojson',
                    'data': e.features[0]
                },
                'layout': {
                    'icon-image': 'location-active',
                    'icon-size': 1.2,
                }
            });

            //Handles popup when a location is clicked
            map.current.on('click', id, handleLocationClick);

            mapLayerIds.current.push(id);
            locationLayerIds.current.push(id);
        }

        let coordinates = e.features[0].geometry.coordinates

        const component = getTripComponent('location', e.features[0].properties.Id);

        //Render the Popup component in the reactDOM to give to mapboxgl to display
        const popupNode = document.createElement("div");
        createRoot(popupNode)
            .render(
                <Popup
                    tripComponentType='location'
                    tripComponent={component}
                    club={ component.club_id ? (clubs.find((club) => club.id === component.club_id)) : null }
                    authUser={authUser}
                    trip={trip}
                    addTripComponent={addTripComponent}
                    removeTripComponent={removeTripComponent}
                    width='350px'
                />
            )
        // wider display for locations
        popupNode.style.width = 'full';
        popupNode.style.maxHeight = '350px';
        popupNode.style.overflowY = 'auto';

        //Display the popup at coordinates using the popupNode and add to the map
        const popup = new mapboxgl.Popup({
            minWidth: '350px',
            maxWidth: '350px',
            maxHeight: '350px',
            overflowY: 'auto'
        })
            .setLngLat(coordinates)
            .setDOMContent(popupNode)
            .addTo(map.current);

        setCurrentPopup(id);

        popup.on('close', () => {
            setCurrentPopup(null);
        })
    };

    //Handle when a user clicks on a trail
    const handleTrailClick = (e) => {
        //Stores the id of this trail to get its line layer. The actual click 
        //event is fired on the trail _target_ layer, so this strips out the 
        //"-target" suffix since the actual logic happens on the line layer.
        const targetLayerId = e.features[0].layer.id;
        const layerId = targetLayerId.substring(0, targetLayerId.length - 7);

        //Uses the id to find the trail's layer and set its color to grey to show it's selected
        map.current.setPaintProperty(layerId, 'line-color', '#D3D3D3')

        //Find a rough midpoint in the trail to display the popup in a reasonable spot when clicked
        let midpoint = Math.floor(e.features[0].geometry.coordinates.length / 2);
        //Store coordinates of the trail's midpoint
        let coordinates = e.features[0].geometry.coordinates[midpoint].slice();
        
        const component = getTripComponent('trail', e.features[0].properties.Id);

        //Render the Popup component in the reactDOM to give to mapboxgl to display
        const popupNode = document.createElement('div');
        createRoot(popupNode)
            .render(
                <Popup
                    tripComponentType='trail'
                    tripComponent={component}
                    club={ component.club_id ? (clubs.find((club) => club.id === component.club_id)) : null }
                    authUser={authUser}
                    trip={trip}
                    addTripComponent={addTripComponent}
                    removeTripComponent={removeTripComponent}
                />
            )

        // remove all old popups
        map.current?.fire('closeAllPopups');

        //Display the popup at coordinates using the popupNode and add to the map
        const popup = new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setDOMContent(popupNode)
            .addTo(map.current);
        
        setCurrentPopup(layerId);

        //Will check if the trail selected is in the trip when closing out of the popup
        //If in the trip, the color is changed to red. If not, the color is changed to black.
        popup.on('close', () => {
            setCurrentPopup(null);
        });

        map.current.on('closeAllPopups', () => {
            popup.remove();
        })
    };

    //This effect deals with creating a layer for the entire trail dataset and for each trail individually when the map is loaded
    useEffect(() => {
        map.current?.fire('closeAllPopups');

        //Only proceed if data has been loaded and the map hasn't loaded yet.
        if (mapDataLoading || map.current) return;

        //Don't proceed without a trip if in display mode
        if (mapType === 'display' && !(trip?.id)) return;

        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PRIVATE_KEY;

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/jake-trx/claj2g5oa002h14pqftnuf819',
            center: [longitude ?? -68.8, latitude ?? 44.8],
            zoom: [zoom ?? 6],
            attributionControl: false
        });

        //Determine the origin point for the map. For display mode, 
        //grab the location of the first component for the trip. Otherwise 
        //start in the middle of Maine.
        if (mapType === 'display') {
            const firstComponent = Object.values(trip.components)[0];
            const componentData = getTripComponent(firstComponent.type, firstComponent.id);
            
            if (firstComponent.type === 'trail') {
                mapBounds.current = new mapboxgl.LngLatBounds(componentData.coordinates[0], componentData.coordinates[0]);
            } else {
                mapBounds.current = new mapboxgl.LngLatBounds([componentData.longitude, componentData.latitude], [componentData.longitude, componentData.latitude]);
            }
        } else {
            mapBounds.current = new mapboxgl.LngLatBounds([-68.8, 44.8], [-68.8, 44.8]);
        }

        //limit the max zoom level
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            map.current.setMinZoom(5);
        } else {
            map.current.setMinZoom(6);
        }

        map.current.addControl(customAttribution, 'bottom-left'); // add custom attribution control to map

        map.current.on('load', () => {
            setMapLoading(false);
        });
    }, [mapDataLoading, trip]);

    //Updates the layers based on the current trip
    useEffect(() => {
        //Only proceed once the map has loaded
        if (mapLoading) return;

        // loop through trails and remove from map by layer id
        if (allTrails) {
            allTrails.forEach((trail) => {
                // if exists, remove
                if (map.current.getLayer('trail-' + trail.id)) {
                    map.current.removeLayer('trail-' + trail.id);
                    // remove source
                    map.current.removeSource('trail-' + trail.id);

                    // remove click event
                    map.current.off('click', 'trail-' + trail.id, eventListeners.current.trails);
                }

                if (map.current.getLayer('trail-' + trail.id + '-target')) {
                    map.current.removeLayer('trail-' + trail.id + '-target');
                    // remove source
                    map.current.removeSource('trail-' + trail.id + '-target');

                    // remove click event
                    map.current.off('click', 'trail-' + trail.id + '-target', eventListeners.current.trails);
                }
            });
        }

        // remove locations from map
        if (locations) {
            locations.forEach((location) => {
                if (map.current.getLayer('location-' + location.id)) {
                    map.current.removeLayer('location-' + location.id);
                    // remove source
                    map.current.removeSource('location-' + location.id);

                    // remove click event
                    map.current.off('click', 'location-' + location.id, eventListeners.current.locations);
                }
            });

            if (map.current.getLayer('locations')) {
                map.current.removeLayer('locations');
                // remove source
                map.current.removeSource('locations');

                // remove click event
                map.current.off('click', 'locations', eventListeners.current.locations);
            }
        }

        // only show enabled trails and those that haven't been explicitly set
        let filteredTrails = allTrails.filter(trail => trail.trail_type == tripType && (trail.visible_to_users == 'yes' || trail.visible_to_users == undefined) && trail.coordinates && trail.coordinates.length > 0);

        // for each filterd trail if visible_to_users is not 'yes' print it
        filteredTrails.map(trail => {
            if (trail.visible_to_users != 'yes') {
                console.log('Trail not visible to users:', trail);
            }
        });

        // filter trails based on trip type
        setTrails(filteredTrails);

        let trails = filteredTrails;

        //In interactive mode, load all trails; in display mode, 
        //only load those that are part of the trip
        const mapTrails = (mapType === 'interactive') ?
            trails :
            trails.filter((trail) => Object.values(trip.components).find((component) => (component.type === 'trail' && component.id === trail.id)));

        // clear trail layer ids
        trailLayerIds.current = [];

        //Create a layer for each trail. This will allow the program to specifically change
        //a trail's color if it is not selected, selected, or not selected and in the trip.
        mapTrails.forEach((trail) => {
            const properties = {
                Id: trail.id,
                ClubId: trail.club_id,
                Duration: trail.duration,
                Mileage: trail.mileage,
                Name: trail.name
            };

            //Add a layer for the line to show on the map for a trail
            map.current.addLayer({
                'id': 'trail-' + trail.id,
                'type': 'line',
                'source': {
                    'type': 'geojson',
                    'data': {
                        'geometry': {
                            'coordinates': [trail.coordinates],
                            'type': 'MultiLineString'
                        },
                        'properties': properties,
                        'type': 'Feature'
                    },
                },
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': '#000',
                    'line-width': 3
                }
            });

            //Add a transparent, wider line to use as a click target for 
            //each trail
            map.current.addLayer({
                'id': 'trail-' + trail.id + '-target',
                'type': 'line',
                'source': {
                    'type': 'geojson',
                    'data': {
                        'geometry': {
                            'coordinates': [trail.coordinates],
                            'type': 'MultiLineString'
                        },
                        'properties': properties,
                        'type': 'Feature'
                    },
                },
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': '#000',
                    'line-width': 20,
                    'line-opacity': 0,
                }
            });

            trailLayerIds.current.push('trail-' + trail.id);
            trailTargetLayerIds.current.push('trail-' + trail.id + '-target');

            //Add the trail to the bounds of the map
            trail.coordinates.map((coordinates) => mapBounds.current.extend(coordinates));
        });

        //Handle clicks for interactive maps
        if (mapType === 'interactive') {
            map.current.on('click', trailTargetLayerIds.current, handleTrailClick);
            eventListeners.current.trails = handleTrailClick;
        }

        if (!map.current.hasImage('location')) {
            const mapLocationIcon = new Image(24, 24);
            mapLocationIcon.onload = () => map.current.addImage('location', mapLocationIcon);
            mapLocationIcon.src = locationIcon;
        }

        if (!map.current.hasImage('location-active')) {
            const mapActiveLocationIcon = new Image(24, 24);
            mapActiveLocationIcon.onload = () => map.current.addImage('location-active', mapActiveLocationIcon);
            mapActiveLocationIcon.src = activeLocationIcon;
        }

        if (!map.current.hasImage('location-selected')) {
            const mapSelectedLocationIcon = new Image(24, 24);
            mapSelectedLocationIcon.onload = () => map.current.addImage('location-selected', mapSelectedLocationIcon);
            mapSelectedLocationIcon.src = selectedLocationIcon;
        }

        //If there's an active trip, load its location components
        const tripLocations = trip ?
            locations.filter((location) => Object.values(trip.components).find((component) => (component.type === 'location' && component.id === location.id))) :
            [];

        //In interactive mode, load all locations; in display mode, 
        //only load those that are part of the trip
        const mapLocations = (mapType === 'interactive') ? locations : tripLocations;

        let features = [];

        mapLocations.forEach(location => {
            features.push({
                'type': 'Feature',
                'properties': {
                    'Id': location.id,
                    'Name': location.name
                },
                'geometry': {
                    'type': 'Point',
                    'coordinates': [location.longitude, location.latitude]
                }
            });

            //Add the location to the bounds of the map
            mapBounds.current.extend([location.longitude, location.latitude]);
        });

        //Create a single base layer for all the locations
        map.current.addLayer({
            'id': 'locations',
            'type': 'symbol',
            'source': {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': features,
                }
            },
            'layout': {
                'icon-image': (mapType === 'interactive') ? 'location' : 'location-selected',
                'icon-size': 1.2,
            }
        });

        if (mapType === 'interactive') {
            //Create individual layers for points on the trip
            tripLocations.forEach((location) => {
                map.current.addLayer({
                    'id': 'location-' + location.id,
                    'type': 'symbol',
                    'source': {
                        'type': 'geojson',
                        'data': {
                            'type': 'Feature',
                            'properties': {
                                'Id': location.id,
                                'Name': location.name
                            },
                            'geometry': {
                                'type': 'Point',
                                'coordinates': [location.longitude, location.latitude]
                            }
                        }
                    },
                    'layout': {
                        'icon-image': 'location-selected',
                        'icon-size': 1.2,
                    }
                });

                map.current.on('click', 'location-' + location.id, handleLocationClick);

                mapLayerIds.current.push('location-' + location.id);
                locationLayerIds.current.push('location-' + location.id);
            });

            //Handles popup when a location is clicked
            map.current.on('click', 'locations', handleLocationClick);
            eventListeners.current.locations = handleLocationClick;
        }

        //Reposition map to fit all the included features
        if (mapType === 'display') {
            map.current.fitBounds(mapBounds.current, {
                padding: 40
            });
        }
    }, [mapLoading, tripType]);

    // When trip type is changed, set map to loading
    // useEffect(() => {
    //     if (!mapLoading) {
    //         setMapLoading(false); // keep false, trigger reload
    //     }
    // }, [tripType]);

    //Refresh the state of the layers on changes
    useEffect(() => {
        //Only do this once the map has loaded and on interactive mode
        if (mapLoading || !map.current || mapType !== 'interactive') return;

        //Decide what color to paint each trail. If it's the trail associated with the 
        //current popup, paint it gray. Paint it red if there's a current trip and the 
        //trail is part of the trip. Otherwise paint it black.
        trailLayerIds.current.forEach((layerId) => {
            if (currentPopup && layerId.split('-')[0] === 'trail' && currentPopup === layerId) {
                map.current.setPaintProperty(layerId, 'line-color', '#D3D3D3');
            } else if (trip?.id && hasTripComponent('trail', layerId.substring(6))) {
                map.current.setPaintProperty(layerId, 'line-color', '#E53935');
            } else {
                map.current.setPaintProperty(layerId, 'line-color', '#000000');
            }
        });

        //Decide what color to paint each location. 
        locationLayerIds.current.forEach((layerId) => {
            if (currentPopup && layerId.split('-')[0] === 'location' && currentPopup === layerId) {
                map.current.setLayoutProperty(layerId, 'icon-image', 'location-active');
            } else if (trip?.id && hasTripComponent('location', layerId.substring(9))) {
                map.current.setLayoutProperty(layerId, 'icon-image', 'location-selected');
            } else {
                map.current.setLayoutProperty(layerId, 'icon-image', 'location');
            }
        });
    
        //Refresh the event listeners for trail layers (mostly to make sure 
        //the state of the popup is accurate)
        map.current.off('click', trailTargetLayerIds.current, eventListeners.current.trails);
        map.current.on('click', trailTargetLayerIds.current, handleTrailClick);
        eventListeners.current.trails = handleTrailClick;

        //Refresh the event listeners for trail layers (mostly to make sure 
        //the state of the popup is accurate)
        map.current.off('click', [...locationLayerIds.current, 'locations'], eventListeners.current.locations);
        map.current.on('click', [...locationLayerIds.current, 'locations'], handleLocationClick);
        eventListeners.current.locations = handleLocationClick;
    }, [mapLoading, trip, currentPopup]);

    //Flies to coordinates of trail/location when a user selects it on the sidebar
    useEffect(() => {
        if (!map.current || mapType !== 'interactive') return;

        map.current.flyTo({ center: [longitude, latitude], zoom: zoom })
    }, [map, latitude, longitude, zoom]);

    return (
        <div ref={mapContainer} id="mapContainer"></div>
    );
}