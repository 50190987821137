import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import { config } from '../../firebase/firebase';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Box, Button, List, ListItem, ListItemText, Typography } from '@mui/material';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import FirebaseRealtimeDatabaseProvider from '../../providers/FirebaseRealtimeDatabaseProvider';

const paragraphStyle = {
    fontFamily: 'Open Sans',
    margin: 0,
    fontSize: 13
};

// Function to determine the closest point (either first or last coordinate of the original feature)
function findClosestPoint(newPoint, points) {
    const distances = points.map(point => Math.hypot(point[0] - newPoint[0], point[1] - newPoint[1]));
    const minDistance = Math.min(...distances);
    return points[distances.indexOf(minDistance)];
}

const TrailEditMap = ({ initialCoordinates, coordinates, setCoordinates, lengthInMiles, setLengthInMiles, targetTrailId, navigate }) => {
    const mapContainerRef = useRef();
    const mapRef = useRef();
    const drawRef = useRef();
    const [lastClick, setLastClick] = useState(null);
    const [trailFeatureId, setTrailFeatureId] = useState(null);

    const [nonEditableTrails, setNonEditableTrails] = useState([]);

    useEffect(() => {
        const dataProvider = FirebaseRealtimeDatabaseProvider(config.databaseURL);
        dataProvider.getList('trails').then((response) => {
            if (response && response.data) {
                const trails = response.data

                setNonEditableTrails(trails);
            }
        });
    }, []);

    useEffect(() => {
        if (!mapRef.current) return;

        const map = mapRef.current;

        if (nonEditableTrails.length > 0) {
            let _trails = nonEditableTrails.filter(trail => trail.id !== targetTrailId);

            // clear all layers from map
            _trails.forEach(trail => {
                if (map.getLayer(`trail-${trail.id}`)) {
                    map.removeLayer(`trail-${trail.id}`);
                }
            });

            map.on('load', () => {
                _trails.forEach(trail => {
                    const layerId = `trail-${trail.id}`;
                    map.addLayer({
                        id: layerId,
                        type: 'line',
                        source: {
                            type: 'geojson',
                            data: {
                                type: 'Feature',
                                properties: {},
                                geometry: {
                                    type: 'LineString',
                                    coordinates: trail.coordinates
                                }
                            }
                        },
                        layout: {
                            'line-join': 'round',
                            'line-cap': 'round'
                        },
                        paint: {
                            'line-color': '#888',
                            'line-width': 3
                        }
                    });

                    // Add click event listener for the layer
                    map.on('click', layerId, () => {
                        if (!window.confirm(`Would  you like to edit ${trail.name} instead? Any unsaved changes here will be lost.`)) {
                            return;
                        }
                        navigate(`/admin/trail-editor/${trail.id}`);
                    });
                });
            });
        }
    }, [nonEditableTrails, mapRef, targetTrailId])

    function loadInitialData(coords) {
        if (!coords || coords.length === 0) return;
        if (!drawRef.current) return;
        const draw = drawRef.current;
        draw.deleteAll();
        const feature = {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'LineString',
                coordinates: coords
            }
        };

        draw.add(feature);

        // set the trail feature id
        setTrailFeatureId(draw.getAll().features[0].id);
        updateLength();
    }

    function updateLength() {
        if (!drawRef.current) return;
        const draw = drawRef.current;

        const data = draw.getAll();
        if (
            data.features.length > 0 &&
            data.features[0].geometry.type === 'LineString'
        ) {
            const length = turf.length(data, { units: 'miles' });
            setLengthInMiles(Math.round(length * 100) / 100);
            setCoordinates(data.features[0].geometry.coordinates);
        } else {
            setLengthInMiles(0);
        }
    }

    useEffect(() => {
        loadInitialData(initialCoordinates);
    }, [initialCoordinates]);

    useEffect(() => {
        // get min and max of coordinates to determine center and zoom level
        let bounds = null;
        let zoomLevel = 6; // Default zoom level

        if (coordinates.length > 0) {
            bounds = coordinates.reduce((bounds, coord) => {
                return bounds.extend(coord);
            }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
        }

        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PRIVATE_KEY;
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: bounds ? bounds.getCenter().toArray() : [-70.7899, 44.4045],
            zoom: zoomLevel
        });

        if (bounds) {
            map.fitBounds(bounds, { padding: 100 });
        }

        const draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                line_string: true,
                trash: true,
                point: false,
            },
            styles: [
                {
                    id: 'gl-draw-line',
                    type: 'line',
                    filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
                    paint: {
                        'line-color': '#000',
                        'line-width': 4
                    }
                },
                {
                    id: 'vertex',
                    type: 'circle',
                    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex']],
                    paint: {
                        'circle-radius': 5,
                        'circle-color': '#FFF'
                    }
                }
            ]
        });

        map.addControl(draw);
        drawRef.current = draw;
        mapRef.current = map;

        map.on('load', () => {
            loadInitialData(coordinates);
        });

        map.on('draw.create', e => {
            const existingFeatures = draw.getAll();
        
            // Only proceed if there is more than one feature
            if (existingFeatures.features.length > 1) {
                let newFeature = existingFeatures.features[1];

                /// delete new feature
                draw.delete(newFeature.id);

                // Reselect the new (updated) feature
                draw.changeMode('simple_select', { featureIds: [trailFeatureId] });
                alert('Only one trail is allowed at a time. Please delete the existing trail before creating a new one.');
            } else {
                // If there's only one feature or none, simply update length or other properties
                updateLength();

                // if feature 0, set the trail feature id
                if (existingFeatures.features.length === 1) {
                    setTrailFeatureId(existingFeatures.features[0].id);
                }
            }
        });
        

        map.on('draw.delete', (e) => {
            // if target is a LineString, verify with user first
            if (e.features[0].geometry.type === 'LineString') {
                if (!window.confirm('Are you sure you want to delete the entire trail? There is no undo.')) {
                    // add the feature back in
                    draw.add(e.features[0]);
                    return;
                }
            }

            updateLength()
        });
        map.on('draw.update', e => {
            updateLength();
        });

        // Enable adding new vertices to an existing line with right-click
        map.on('click', (e) => {
            // set last click equal to coordinates of the click
            setLastClick(e.lngLat);

            // if shift is held down
            if (e.originalEvent.altKey) {
                const features = map.queryRenderedFeatures(e.point);

                if (features.length) {
                    const clickedFeature = features[0];

                    // Check if the clicked feature is a vertex
                    if (clickedFeature.properties && clickedFeature.properties.meta === 'vertex') {
                        // find the vertex's index in the line
                        let vertexIndex = clickedFeature.properties.coord_path;

                        const line = draw.getAll().features[0]
                        let lineCoordinates = line.geometry.coordinates;

                        // insert a new coordinate into the line
                        lineCoordinates.splice(vertexIndex, 0, [e.lngLat.lng + 0.005, e.lngLat.lat + 0.005]);

                        // Remove the existing feature
                        draw.delete(line.id);

                        // Add a new feature with the updated geometry
                        draw.add({
                            id: line.id,
                            type: 'Feature',
                            properties: {},
                            geometry: {
                                type: 'LineString',
                                coordinates: lineCoordinates
                            }
                        });

                        // select
                        draw.changeMode('direct_select', { featureId: line.id });
                    }
                }
            }
        });              

        return () => {
            map.remove();
        };
    }, []);

    return (
        <>
            <Box sx={{ display: 'flex', gap: 1, padding: 1 }}>
                <Button variant="outlined" color="secondary" onClick={() => loadInitialData(initialCoordinates)}>
                    Undo all changes
                </Button>
                <Button variant="outlined" color="secondary" 
                    onClick={() => {
                        if (!lastClick || !trailFeatureId) return;
    
                        if (!drawRef.current) return;
                        const draw = drawRef.current;
                        const data = draw.getAll();
    
                        // get closest end point from the feature to the last click position
                        const closestPoint = findClosestPoint([lastClick.lng, lastClick.lat], [data.features[0].geometry.coordinates[0], data.features[0].geometry.coordinates[data.features[0].geometry.coordinates.length - 1]]);
    
                        draw.changeMode('draw_line_string', {
                            featureId: trailFeatureId,
                            from: closestPoint
                        });
                    }}
                >
                    Extend Line
                </Button>
            </Box>
            <Box
                ref={mapContainerRef}
                sx={{ height: 750, mx: 3, width: '100%' }}
            />
            <Box
                sx={{
                    height: 75,
                    width: 150,
                    position: 'absolute',
                    bottom: 40,
                    left: 10,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    padding: 2,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
            >
                <Typography>
                    Click the map to draw a line.
                </Typography>
                {lengthInMiles > 0 && (
                    <Typography variant="body2" fontWeight="bold">
                        {lengthInMiles} miles
                    </Typography>
                )}
            </Box>
        </>
    );    
};

const TrailEditContainer = () => {
    const { trailId } = useParams();
    const [trail, setTrail] = useState(null);
    const navigate = useNavigate();
    const [newCoordinates, setNewCoordinates] = useState([]);
    const [lengthInMiles, setLengthInMiles] = useState(0)
    const [loading, setLoading] = useState(true);

    const dataProvider = FirebaseRealtimeDatabaseProvider(config.databaseURL);

    useEffect(() => {
        document.title = 'Trail Editor | SledTRX';
        // sleep to allow map to load
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    useEffect(() => {
        // get trail at id
        dataProvider.getOne('trails', { id: trailId }).then((response) => {
            if (response && response.data) {
                setTrail(response.data);

                if (response.data.coordinates) {
                    setNewCoordinates(response.data.coordinates);
                } else {
                    setNewCoordinates([]);
                }
            }
        });
    }, [trailId]);

    const handleSave = () => {
        // confirm that the user wants to save the trail
        if (!window.confirm('Are you sure you want to save the trail? The changes will go live for all users.')) {
            return;
        }

        let _trail = { ...trail };
        _trail.coordinates = newCoordinates;
        _trail.mileage = lengthInMiles;
        _trail.duration = Math.round(lengthInMiles * 2.5); // assume ~24 miles per hour

        // for each coordinate entry, if no third index, add 0
        _trail.coordinates = _trail.coordinates.map(coord => {
            if (coord.length === 2) {
                return [coord[0], coord[1], 0];
            } else {
                return coord;
            }
        });

        const params = {
            id: trailId,
            data: _trail
        }

        // save the trail
        dataProvider.update('trails', params).then(() => {
            navigate('/admin/trails');
        });
    };

    const handleReturn = () => {
        navigate('/admin/trails');
    };

    return (
        !loading ? (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '20px',
                gap: '20px',
                backgroundColor: '#f4f4f4', // a light grey background for subtle contrast
                borderRadius: '8px', // rounded corners for a softer look
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)' // subtle shadow for depth
            }}>
                <Typography variant="h5" sx={{ color: '#333' }}>
                    Trail Editor for {trail?.name || 'your trail'}
                </Typography>
                
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '80%', // Adjust based on the width of the container
                    maxWidth: '500px', // more compact width
                    background: '#fff', // white background for the instruction area
                    padding: 2,
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                }}>
                    <Box sx={{ width: '100%' }}>
                        <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'medium' }}>Instructions</Typography>
                        <List dense>
                            <ListItem>
                                <ListItemText primary="Add Vertex: Select a trail, then alt + click on a vertex to add a new vertex." />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Extend: Click on the end of the trail you want to extend from, then click 'Extend Line'" />
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    mt: 2
                }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleReturn}>
                        Return to Admin Area
                    </Button>
                </Box>
                {trail ? (
                    <TrailEditMap 
                        initialCoordinates={trail?.coordinates} 
                        coordinates={newCoordinates} 
                        setCoordinates={setNewCoordinates} 
                        lengthInMiles={lengthInMiles} 
                        setLengthInMiles={setLengthInMiles} 
                        targetTrailId={trail.id} 
                        navigate={navigate}
                        sx={{ width: '100%', height: '500px' }} // Adjust height as needed
                    />
                ) : null}
            </Box>
        ) : null
    );    
};


export default TrailEditContainer;