import './Home.css';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DOMPurify from 'dompurify';
import TopographImage from '../../images/LandingPage/ATV/TopographyMap.jpg';
import AppPreviewImage from '../../images/LandingPage/ATV/AppPreview.png';

import FirebaseRealtimeDatabaseProvider from '../../providers/FirebaseRealtimeDatabaseProvider';

import AccountButtons from '../../components/AccountButtons/AccountButtons';

import Logo from '../../images/Header/SledTRXLogo.png';
import DuraBullLogo from '../../images/Affiliates/durabull_full_logo.jpeg';
import BrapWrapLogo from '../../images/Affiliates/BrapWrap_logo.png';
import LargeImage from '../../images/LandingPage/ATV/ATV3.jpg';
import SmallImage1 from '../../images/LandingPage/ATV/ATV1.png';
import SmallImage2 from '../../images/LandingPage/ATV/ATV2.jpg';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const AppStoreButtons = () => {
    return (
        <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center" 
            sx={{ mt: 2 }}
        >
            <a href="https://apps.apple.com/app/6499356376" target="_blank">
                <img 
                    src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" 
                    alt="Download on the App Store" 
                    style={{ maxWidth: '150px', height: '50px' }}
                />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.sledtrx.sledtrx" target="_blank">
                <img 
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" 
                    alt="Get it on Google Play" 
                    style={{ maxWidth: '200px', height: '75px' }}
                />
            </a>
        </Box>
    )
};

function useWindowWidth() {
    // Step 2: Create a state variable for the width
    const [width, setWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      // Function to update state to current window width
      const handleResize = () => {
        setWidth(window.innerWidth);
      };
  
      // Step 3: Add event listener for window resize
      window.addEventListener('resize', handleResize);
  
      // Step 4: Remove event listener on cleanup
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); // Empty array ensures this effect runs only once on mount
  
    return width;
  }

const Home = () => {
    const [page, setPage] = useState('');
    const [intro, setIntro] = useState(null);
    const [content, setContent] = useState(null);
    const width = useWindowWidth();

    const dataProvider = FirebaseRealtimeDatabaseProvider();

    useEffect(() => {
        dataProvider.getList('pages', {
            sort: { field: 'slug' },
            filter: { slug: 'Home' }
        }).then((result) => {
            setPage(result.data.length ? result.data[0] : {});
            if (result.data[0]?.intro) {
                setIntro(DOMPurify.sanitize(result.data[0].intro));
            }
            if (result.data[0]?.content) {
                setContent(DOMPurify.sanitize(result.data[0].content));
            }
        });
    }, []);

    //Creates code to embed a video from a YouTube link
    const getVideoEmbed = () => {
        if (!page?.video_url) return null;

        //Pluck the ID of the video from the link
        const url = new URL(page.video_url);

        //Supported URL formats:
        //- https://www.youtube.com/watch?v=___
        //- https://youtu.be/___
        let videoId = null;
        if (url.host.match('youtube.com')) {
            videoId = url.searchParams.get('v');
        } else if (url.host.match('youtu.be')) {
            videoId = url.pathname.substring(1);
        }

        const embedSrc = `https://www.youtube.com/embed/${videoId}`;

        return (
            <iframe 
                width="560"
                height="315" 
                src={embedSrc}
                title="YouTube video player" 
                allowFullScreen
            ></iframe>
        );
    };

    return (
        <Stack spacing={0} class="home-container" justifyContent="center" alignItems="center">
            <Box id="photo-grid">
                <img src={LargeImage} alt="SledTRX Logo" id="large-image"></img>
                <Box id="overlay-container">
                    <AppStoreButtons />
                    <Button 
                        href="#mobile-app-info" 
                        id="more-info-link"
                        onClick={(e) => {
                            e.preventDefault();
                            const infoElement = document.getElementById('mobile-app-info');
                            if (infoElement) {
                                infoElement.scrollIntoView({ behavior: 'smooth' });
                                // Add the highlight class
                                infoElement.classList.add('highlight');
                                // Remove the highlight class after 2 seconds
                                setTimeout(() => {
                                    infoElement.classList.remove('highlight');
                                }, 2000); // Adjust time as needed
                            }
                        }}
                    >
                        Tap to learn more!
                    </Button>
                </Box>
                <Box id="small-image-container">
                    <img src={SmallImage1} alt="SledTRX Logo" className="small-image"></img>
                    <img src={SmallImage2} alt="SledTRX Logo" className="small-image"></img>
                </Box>
            </Box>
            <Box 
                display="flex" 
                flexDirection="column" 
                justifyContent="center" 
                alignItems="center" 
                sx={{
                    textAlign: 'center',
                    mt: 0,
                    p: 0,
                    backgroundImage: `url(${TopographImage})`,
                    backgroundSize: 'auto', // Changed from 'cover' to 'auto' to prevent stretching
                    backgroundRepeat: 'repeat', // Added to make the image repeat
                    backgroundPosition: 'center',
                }}
            >
                <Box id="signup-container">
                    <img src={Logo} alt="SledTRX Logo" id="logo"></img>
                </Box>
                <Box sx={{ marginBottom: '2em' }}>
                    <AccountButtons />
                    <Button 
                        href="/Map" 
                        id="explore-map-link" 
                    >
                        Explore the Map
                    </Button>
                </Box>
                <Typography 
                    variant="h2" 
                    sx={{ fontSize: '26px', fontWeight: 'bold', mb: '0.5em', mt: '0.25em' }}
                >
                    Exploring made easy.
                </Typography>
                <Typography 
                    sx={{ fontSize: '20px', mb: '1em' }}
                >
                    A Snowmobile and ATV mapping service allowing riders to explore news regions and feel secure while out on the trail. Use our website or mobile app to build your personal route for your crew this weekend!
                </Typography>
                <Typography 
                    variant="h2" 
                    sx={{ fontSize: '26px', fontWeight: 'bold', mb: '0.5em', mt: '0.25em' }}
                    id="mobile-app-info"
                >
                    Signup to use our trip-builder or mobile app!
                </Typography>
                {width > 900 ?
                    <Box id="app-preview-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
                        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', paddingBottom: '120px', paddingLeft: '30px' }}>
                            <Typography
                                style={{ fontSize: '20px', textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '10px', maxWidth: '450px', marginBottom: '100px' }}
                                class="mobile-app-info"
                            >
                                New users can have access to Maine ATV & Snowmobile Trails Statewide!
                            </Typography>
                            <Typography
                                style={{ fontSize: '20px', textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '10px', maxWidth: '450px' }}
                                class="mobile-app-info"
                            >
                                Build your trip right <Link component="button" to="/Map">here</Link> on the site!
                            </Typography>
                        </Box>
                        <img
                            src={AppPreviewImage}
                            alt=""
                            id="logo-mobile"
                            style={{
                                margin: '1em',
                                width: '100%',
                                maxWidth: '400px',
                                minWidth: '300px',
                            }}
                        />
                        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '80%', paddingRight: '30px' }}>
                            <Typography
                                style={{ fontSize: '20px', textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '10px', maxWidth: '450px', marginBottom: '100px' }}
                                class="mobile-app-info"
                            >
                                Profiles allow users to build and save custom trips!
                            </Typography>
                            <Typography
                                style={{ fontSize: '20px', textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '10px', maxWidth: '450px' }}
                                class="mobile-app-info"
                            >
                                Access your trips on our mobile app!
                            </Typography>
                        </Box>
                    </Box>
                : <>
                    <img
                        src={AppPreviewImage}
                        alt=""
                        id="logo-mobile"
                        style={{
                            margin: '1em',
                            width: '90%',
                            maxWidth: '400px',
                        }}
                    ></img>
                    <Typography 
                        sx={{ fontSize: '20px', mb: '1em', textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '10px' }}
                        class="mobile-app-info"
                    >
                        <ul style={{ margin: 0, paddingLeft: '20px' }}>
                            <li>New users can have access to Maine ATV & Snowmobile Trails Statewide!</li>
                            <li>Profiles allow users to build and save custom trips!</li>
                            <li>Build your trip right <Link component="button" to="/Map">here</Link> on the site and access your trips on our mobile app!</li>
                        </ul>
                    </Typography>
                </>}
                <AppStoreButtons />
                <Box className="affiliates-container">
                    <Box className="affiliate">
                        <img src={DuraBullLogo} id="durabull-logo" alt="DuraBull Trailers Logo" className="affiliate-logo" />
                        <Typography>
                            SledTRX is an affiliate of <a href="https://www.durabulltrailers.com/" target="_blank" className="strong alternate-link-color">DuraBull Trailers</a>
                        </Typography>
                    </Box>
                    <Box className="affiliate">
                        <img src={BrapWrapLogo} id="second-affiliate-logo" alt="Second Affiliate Logo" className="affiliate-logo" />
                        <Typography>
                            SledTRX is an affiliate of <a href="https://www.brapwrap.com" target="_blank" className="strong alternate-link-color">Brap Wrap</a>
                        </Typography>
                    </Box>
                </Box>
            </Box>
            { intro ?
                <Box class="intro">
                    <div dangerouslySetInnerHTML={{__html: intro}} />
                </Box> : null
            }
            { getVideoEmbed() ?
                <Box sx={{ mx: 'auto' }} className="home-video">
                    {getVideoEmbed()}
                </Box> : null
            }
            <Box>
                <div dangerouslySetInnerHTML={{__html: content}} />
            </Box>
        </Stack>
    );
};

export default Home;