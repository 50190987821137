import { child, endAt, equalTo, get, orderByChild, orderByKey, push, query, ref, set, startAt } from 'firebase/database';
import { db } from '../firebase/firebase';

//This was built as a data provider to be compatible with React Admin's architecture, 
//but it could also be used outside of React Admin.
const FirebaseRealtimeDatabaseProvider = () => ({
    /**
     * Get a list of all (or filtered) records
     * 
     * `params` can include:
     *   - sort.field: the field by which to sort (default: sort by the key)
     *   - filter.q: a string of text by which to filter
     *   - filter.{key}: a string of text by which to match
     *   - filter.pagination.perPage: number of resuts to return by page
     *   - filter.pagination.page: page number to return
     * 
     * Returns an array in the following format, with the records and a count of 
     * the total (unpaginated) records in the set. Each record will include an 
     * id, which is the value of the record's key.
     * 
     *   {
     *     data: [
     *       {
     *         id: ...
     *         ...
     *       },
     *       ...
     *     ],
     *     total: 100
     *   }
     * 
     * @param {string} resource Name of the resource (e.g., 'items')
     * @param {Object} params Parameters in the format described
     * @return {Object} Data result of the query, in the format described
     */
    getList: async (resource, params) => {
        let constraints = [];
        constraints.push((params && params.sort) ? orderByChild(params.sort.field) : orderByKey());

        //Filter for matches based on the value(s) of `params.filter`
        if (params && params.filter) {
            for (const key in params.filter) {
                if (key === 'q') {
                    //This allows for partial word matches
                    constraints.push(startAt(params.filter[key]));
                    constraints.push(endAt(params.filter[key]+ '\uf8ff'));
                } else {
                    constraints.push(equalTo(params.filter[key]));
                }
            }
        }
        
        return await get(query(ref(db, resource), ...constraints))
            .then((snapshot) => {
                const entries = snapshot.val();

                const rangeStart = (params && params.pagination) ? ((params.pagination.page - 1) * params.pagination.perPage) : null;
                const rangeEnd = (params && params.pagination) ? (params.pagination.page * params.pagination.perPage) : null;

                //Build an array of entries to return based on whether or not there's pagination
                let returnableEntries = [];
                if (entries) {
                    returnableEntries = (params && params.pagination) ?
                        Object.entries(entries).slice(rangeStart, rangeEnd) :
                        Object.entries(entries);
                    returnableEntries = returnableEntries.map(entry => ({ ...entry[1], id: entry[0] }));
                }

                const totalEntries = entries ? Object.entries(entries).length : 0;
                
                return { 
                    data: returnableEntries,
                    total: totalEntries,
                };
            });
    },

    /**
     * Gets a single record by ID
     * 
     * `params` can include:
     *   - id: the key of the record to fetch
     * 
     * Returns an array in the following format, including an 
     * id, which is the value of the record's key.
     * 
     *   {
     *     data: [
     *       {
     *         id: ...
     *         ...
     *       },
     *       ...
     *     ]
     *   }
     * 
     * @param {string} resource Name of the resource (e.g., 'items')
     * @param {Object} params Parameters in the format described
     * @return {Object} Data of the queried record, in the format described
     */
    getOne: async (resource, params) => await get(ref(db, `${resource}/${params.id}`))
        .then((snapshot) => ({ data: { ...snapshot.val(), id: params.id } })),

    /**
     * Gets a list of records by IDs
     * 
     * `params` can include:
     *   - ids: the IDs of records to fetch
     * 
     * Returns an array in the following format, including an 
     * id, which is the value of the record's key.
     * 
     *   {
     *     data: [
     *       id: ...
     *       ...
     *     ]
     *   }
     * 
     * @param {string} resource Name of the resource (e.g., 'items')
     * @param {Object} params Parameters in the format described
     * @return {Object} Data result of the query, in the format described
     */
    getMany: async (resource, params) => await get(query(ref(db, resource)))
        .then((snapshot) => {
            var matchingEntries = Object.entries(snapshot.val()).filter((entry) => params.ids.includes(entry[0]));

            return {
                data: matchingEntries.map((entry) => ({ ...entry[1], id: entry[0] }))
            };
        }),

    /**
     * Updates the data in a record
     * 
     * `params` can include:
     *   - id: the key of the record to update
     *   - data: data to save
     * 
     * This method will also update the `updated_at` field on the record.
     * 
     * Returns an array in the following format, including an 
     * id, which is the value of the record's key.
     * 
     *   {
     *     data: {
     *       id: ...
     *       ...
     *     }
     *   }
     * 
     * @param {string} resource Name of the resource (e.g., 'items')
     * @param {Object} params Parameters in the format described
     * @return {Object} Data of the updated record, in the format described
     */
    update: (resource, params) => {
        const data = params.data;
        data.updated_at = (new Date()).toISOString();

        //Make sure there aren't any undefined fields in the input set
        for (const dataKey in data) { 
            if (typeof data[dataKey] === 'undefined') {
                data[dataKey] = '';
            }
        }

        return set(ref(db, `${resource}/${params.id}`), data)
            .then((response) => ({ data: { ...data, id: params.id } }));
    },
    
    /**
     * Creates a new record
     * 
     * `params` can include:
     *   - data: data to save
     * 
     * If `params.data` includes an `id`, it will use that ID as the key. 
     * Otherwise it will autogenerate a key.
     * 
     * This method will also update the `created_at` and `updated_at` fields
     * on the record.
     * 
     * Returns an array in the following format, including an 
     * id, which is the value of the record's key.
     * 
     *   {
     *     data: {
     *       id: ...
     *       ...
     *     }
     *   }
     * 
     * @param {string} resource Name of the resource (e.g., 'items')
     * @param {Object} params Parameters in the format described
     * @return {Object} Data of the created record, in the format described
     */
    create: (resource, params) => {
        const data = params.data;
        data.created_at = (new Date()).toISOString();
        data.updated_at = (new Date()).toISOString();

        //Autogenerate a key if one isn't provided
        if (params.data.id == undefined) {
            params.data.id = null
        }
        const key = params.data.id ?? push(child(ref(db), resource)).key;

        //Make sure there aren't any undefined fields in the input set
        for (const dataKey in data) { 
            if (typeof data[dataKey] === 'undefined') {
                data[dataKey] = '';
            }
        }

        return set(ref(db, `${resource}/${key}`), data)
            .then((response) => ({ data: { ...data, id: key } }));
    },

   /**
     * Deletes a record
     * 
     * `params` can include:
     *   - id: the key of the record to delete
     * 
     * Returns an array in the following format, including an 
     * id, which is the value of the record's key.
     * 
     *   {
     *     data: {
     *       id: ...
     *       ...
     *     }
     *   }
     * 
     * @param {string} resource Name of the resource (e.g., 'items')
     * @param {Object} params Parameters in the format described
     * @return {Object} Data of the deleted record, in the format described
     */
   delete: (resource, params) => set(ref(db, `${resource}/${params.id}`), null)
        .then((response) => ({ data: { ...params.data, id: params.id } })),
});

export default FirebaseRealtimeDatabaseProvider;