import React from 'react';
import { AutocompleteInput, Create, ReferenceInput, SelectInput, SimpleForm, TextInput, choices, number, required, useNotify, useRedirect } from 'react-admin';
import Grid from '@mui/material/Grid';

const TrailTitle = () => {
    return <span>New trail</span>;
};

const TrailCreate = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const validationRules = {
        name: [required()],
        status: [required(), choices(['active', 'inactive'])],
        grooming_status: [required(), choices(['groomed', 'bumpy', 'closed'])],
        type: [choices(['main', 'connector'])],
        owner_type: [choices(['club', 'its'])],
        region: [choices(['aroostook', 'downeast', 'portland', 'highlands', 'kennebec', 'beaches', 'lakes-mountains', 'midcoast'])],
        mileage: [number()],
        duration: [number()],
        trail_type: [required(), choices(['snowmobile', 'atv'])],
        visible_to_users: [required(), choices(['yes', 'no'])],
    };

    const onSuccess = () => {
        notify('Trail created');
        redirect('list', 'trails');
    };

    return (
        <>
            <Create title={<TrailTitle />} mutationMode="pessimistic" mutationOptions={{ onSuccess }} {...props}>
                <SimpleForm>
                    <TextInput source="name" validate={validationRules.name} />
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} sm={6}>
                            <SelectInput source="status" choices={[
                                { id: "active", name: "Active" },
                                { id: "inactive", name: "Inactive" },
                            ]} validate={validationRules.status} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SelectInput source="grooming_status" choices={[
                                { id: "groomed", name: "Groomed" },
                                { id: "bumpy", name: "Bumpy" },
                                { id: "closed", name: "Closed" },
                            ]} validate={validationRules.grooming_status} />
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} sm={6}>
                            <SelectInput source="type" choices={[
                                { id: "main", name: "Main" },
                                { id: "connector", name: "Connector" },
                            ]} validate={validationRules.type} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SelectInput source="owner_type" choices={[
                                { id: "club", name: "Club" },
                                { id: "its", name: "ITS" },
                            ]} validate={validationRules.owner_type} />
                        </Grid>
                    </Grid>
                    <SelectInput source="region" choices={[
                        { id: "aroostook", name: "Aroostook County" },
                        { id: "downeast", name: "Downeast & Acadia" },
                        { id: "portland", name: "Greater Portland & Casco Bay" },
                        { id: "highlands", name: "Highlands" },
                        { id: "kennebec", name: "Kennebec Valley" },
                        { id: "beaches", name: "Maine's Beaches" },
                        { id: "lakes-mountains", name: "Maine's Lakes & Mountains" },
                        { id: "midcoast", name: "Midcoast & Islands" },
                    ]} validate={validationRules.region} />
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextInput source="mileage" validate={validationRules.mileage} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextInput source="duration" validate={validationRules.duration} />
                        </Grid>
                    </Grid>
                    <SelectInput source="trail_type" choices={[
                        { id: "snowmobile", name: "Snowmobile" },
                        { id: "atv", name: "ATV" },
                    ]} validate={validationRules.trip_type} />
                    <SelectInput source="visible_to_users" choices={[
                        { id: "yes", name: "Yes" },
                        { id: "no", name: "No" },
                    ]} validate={validationRules.visible_to_users} />
                    <ReferenceInput source="club_id" reference="clubs">
                        <AutocompleteInput
                            label="Club"
                            inputText={(record) => record.name}
                            optionText={(record) => record.name}
                            fullWidth={true} />
                    </ReferenceInput>
                </SimpleForm>
            </Create>
        </>
    );
};

export default TrailCreate;