import './ContentPage.css';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DOMPurify from 'dompurify';

import FirebaseRealtimeDatabaseProvider from '../../providers/FirebaseRealtimeDatabaseProvider';

//The standalone param indicates whether this page lives at the root level (as opposed to 
//a NavLayout). The only real difference is that root pages are padded on the x-axis.
const ContentPage = ({ standalone }) => {
    let location = useLocation();

    const [page, setPage] = useState({});
    const [content, setContent] = useState(null);

    const dataProvider = FirebaseRealtimeDatabaseProvider();

    useEffect(() => {
        const pageName = location.pathname.split('/').pop();

        dataProvider.getList('pages', {
            sort: { field: 'slug' },
            filter: { slug: pageName }
        }).then((result) => {
            setPage(result.data.length ? result.data[0] : {});
            if (result.data[0]?.content) {
                setContent(DOMPurify.sanitize(result.data[0].content));
            }

            //Set the page title
            document.title = page.title ? `${page.title} | SledTRX` : 'SledTRX';
        });
    }, [location]);

    let boxSx = {}
    
    if (page?.header_photo) {
        boxSx = {
            backgroundImage: `url(${page.header_photo.src})`,
        }
    } else {
        boxSx = {
            background: 'none',
            maxHeight: '60px',
        }
    }

    return (
        <Stack spacing={1} sx={{ px: standalone ? 2 : 0 }}>
            <Box
                id="header-image"
                sx={boxSx}
            >
                <Typography
                    variant="h1"
                    sx={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%', // Ensures the text and gradient span the width of the box
                    background: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6))',
                    color: 'white',
                    fontSize: '36px',
                    fontWeight: 'bold',
                    padding: '8px 16px', // Reduced padding around the text
                    boxSizing: 'border-box', // Ensures padding is included in width calculation
                    }}
                >
                    {page?.title}
                </Typography>
            </Box>
            <div dangerouslySetInnerHTML={{__html: content}} className="page-content" />
        </Stack>
    );
};

export default ContentPage;