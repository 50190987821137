import { Stack } from '@mui/material';
import { CreateButton, Datagrid, EditButton, FilterForm, ListBase, Pagination, TextField, TextInput, Title } from 'react-admin';
import { GroomingStatusField } from './GroomingStatusField';
import { StatusField } from './StatusField';

const TrailList = () => {
    const filters = [<TextInput label="Filter" source="q" size="small" alwaysOn />];

    const ListToolbar = () => (
        <Stack direction="row" justifyContent="space-between">
            <FilterForm filters={filters} />
            <CreateButton />
        </Stack>
    );

    return (
        <ListBase sort={{ field: 'name', order: 'ASC' }} exporter={false}>
            <Title title="Trails" />
            <ListToolbar />
            <Datagrid bulkActionButtons={false}>
                <TextField source="name" sortable={false} />
                <GroomingStatusField source="grooming_status" sortable={false} />
                <StatusField source="status" sortable={false} />
                <EditButton />
            </Datagrid>
            <Pagination />
        </ListBase>
    );
};

export default TrailList;