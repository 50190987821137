import { Create, ImageField, ImageInput, SimpleForm, TextInput, regex, required, useNotify, useRecordContext, useRedirect } from 'react-admin';
import { AlignmentButtons, ClearButtons, FormatButtons, LevelSelect, LinkButtons, ListButtons, QuoteButtons, RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import DOMPurify from 'dompurify';

const PageCreate = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const Title = () => {
        const record = useRecordContext();
        return <span>{ record ? record.title : 'New page' }</span>
    };
    
    const validationRules = {
        title: [required()],
        slug: [required(), regex(/[a-zA-Z0-9-]+$/, 'Slug can only include letters, numbers, and dashes')],
    };
    
    const EditorToolbar = ({ size, ...props }) => {
        return (
            <RichTextInputToolbar {...props}>
                <LevelSelect size={size} />
                <FormatButtons size={size} />
                <AlignmentButtons size={size} />
                <ListButtons size={size} />
                <LinkButtons size={size} />
                <QuoteButtons size={size} />
                <ClearButtons size={size} />
            </RichTextInputToolbar>
        );
    };
    
    const onSuccess = () => {
        notify('Page updated');
        redirect('list', 'pages');
    };
    
    return (
        <Create title={<Title />} mutationOptions={{ onSuccess }} {...props}>
            <SimpleForm>
                <TextInput source="title" validate={validationRules.title} />
                <TextInput source="slug" validate={validationRules.slug} />
                <RichTextInput 
                    source="content"
                    format={(value) => DOMPurify.sanitize(value)}
                    toolbar={<EditorToolbar />}
                    fullWidth={true} 
                />
                <ImageInput source="header_photo">
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Create>
    );
};

export default PageCreate;