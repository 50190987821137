import RouteIcon from '@mui/icons-material/Route';
import TrailEdit from './TrailEdit';
import TrailList from './TrailList';
import TrailCreate from './TrailCreate';

const trails = {
    list: TrailList,
    edit: TrailEdit,
    create: TrailCreate,
    icon: RouteIcon,
};

export default trails;